<template>
  <v-row justify="center">
    <v-col cols="12" sm="10">
      <v-row dense>
        <v-col>
          <h1>{{ `${$t('Compare')} ${$t('Dapp')}` }}</h1>
        </v-col>
      </v-row>
      <v-col v-if="loading" >
        <Loader class="pt-16 pb-10"/>
      </v-col>
      <v-col v-else class="pa-0">
        <v-row dense>
          <v-col
            v-for="(asset, index) in assets"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card class="pa-3 my-xs-5" outlined>
              <v-row dense>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="11" class="asset-menu">
                      <v-menu
                        :ref="`assetMenu${index}`"
                        offset-y
                        z-index="4"
                        nudge-left="1"
                        :close-on-content-click="false"
                        class="mt-2"
                      >
                        <template v-slot:activator="{ attrs, on }">
                          <button
                            type="button"
                            small
                            class="asset-btn success elevation-0 text-capitalize body-2"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="onClickAsset(index)"
                          >
                            <span class="font-weight-bold ellipsis">{{ asset.token_address }}</span>
                            <v-icon class="float-right">mdi-chevron-down</v-icon>
                          </button>
                        </template>

                        <v-card class="dropdown-card">
                          <v-card-title class="dropdow-card-title px-1 py-2">
                            <v-text-field
                              :ref="`asset${index}`"
                              v-model="assetModel[index]"
                              class="pa-0 ma-0"
                              hide-details
                              outlined
                              dense
                              flat
                              :loading="currencyLoading[index]"
                              loader-height="3"
                              @keyup="onSearchCurrency(index)"
                            />
                          </v-card-title>
                          <v-list class="dropdown-card-list">
                            <div v-if="currencies.length > 0">
                              <v-list-item
                                v-for="(curr, idx) in currencies"
                                :key="curr.name"
                                class="px-1"
                                @click="onSelectCurrency(index, idx)"
                              >
                                <span class="ml-1 caption">{{ curr.token_address }}</span>
                              </v-list-item>
                            </div>
                            <span v-else class="ml-2">{{ error_search }}</span>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col cols="1" class="flex-grow-0 text-right">
                      <v-btn icon class="button-tiny grey--text mt-1" @click="onRemoveAsset(index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                  <p class="mt-2">
                    <span class="float-left blue-grey--text">{{ $t('Rank') }}</span>
                    <span class="float-right font-weight-bold">{{ asset ? asset.calc_rank : null }}</span>
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                  <p class="mt-2">
                    <span class="float-left blue-grey--text">{{ $t('TransactionCount') }}</span>
                    <span class="float-right font-weight-bold">
                      {{ asset ? `${ helper.convertToBigNumber(asset.trans_count, 0) }` : null }}
                    </span>
                  </p>
                </v-col>
                <v-col cols="12">
                  <p>
                    <span class="float-left blue-grey--text">{{ $t('Value') }}</span>
                    <span class="float-right font-weight-bold">
                      {{ asset ? helper.abbreviateNumber(convertValue(asset.trans_value)) : null }}
                    </span>
                    <br />
                    <span class="float-right caption">
                      {{ checkLongValue(asset.trans_value) }}
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            v-if="assets.length < 8"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="d-flex align-center asset-btn-wrap"
          >
            <v-btn class="success my-0 mx-auto" @click="onAddAsset">
              {{ $t('AddToken') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="my-5"/>
        <v-row dense>
          <v-col cols="12">
            <Loader v-if="chartLoading" :height="`500px`" />
            <template v-else>
              <v-row>
                <v-col>
                  <LineChart :chart-data="historyDataset" />
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
        <v-divider class="my-5"/>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import {
  DAPP_LIST_REQUEST,
  DAPP_HISTORY_REQUEST,
} from '@/store/compare/action'
import { get, call, commit, dispatch } from 'vuex-pathify'
import Loader from '@/components/molecules/Loader'
import helper from '@/utils/helper'
import LineChart from '@/components/organisms/chart/Compare'

export default {
  name:'CompareCurrencies',
  components: {
    Loader,
    LineChart,
  },
  data: () => ({
    helper: helper,
    loading: true,
    minAsset: 2,
    maxAsset: 8,
    timeout: null,
    error_search: '',
    assets: [],
    assetMenu: [],
    assetModel: [],
    currencies: [],
    currencyLoading: [],
    chartLoading: true,
    viewType: 0,
    historyDataset: [],
    rules: {
      required: [(v) => !!v || 'Required.'],
    },
  }),
  computed: {
    list: get('compare/getTokens'),
  },
  beforeMount() {
    if (this.list.length < 1) {
      this.onFetchCurrencies()
      this.onCompare()
    } else {
      this.loading = false
      this.chartLoading = false
      const temp = this.$store.get('compare/getDappAssets')

      this.$set(this, 'currencies', this.list)
      this.$set(this, 'assets', this.onParse(temp))

      this.onSetChartData()
    }
  },
  methods: {
    onSetChartData() {
      const tempData = []

      this.assets.forEach((o, idx) => {
        tempData.push({
          ...o,
          symbol: o.token_address,
          id: o.token_address,
          data: o.data,
          labels: o.dataLabel,
        })
      })

      this.historyDataset = tempData
      this.chartLoading = false
    },
    async onCompare() {
      // fetch currencies
      this.assets.forEach((o, idx) => {
        o['label'] = []
        o['data'] = []
      })

      for await (const [i, item] of this.assets.entries()) {
        const payload = {
          days: '1D',
          sort: 'asc',
          token_address: item.token_address,
          chain: item.chain,
        }

        this.chartLoading = true
        const res = await this.loadHistory(payload)

        this.$set(this.assets, i, {
          ...this.assets[i],
          'data': res.data, // Volume
          'dataLabel': res.label,
        })
      }

      commit('compare/SET_DAPP_ASSETS', this.onParse(this.assets))

      this.onSetChartData()

    },
    onSelectCurrency(assetIndex, currencyIndex) {
      this.$refs[`assetMenu${assetIndex}`][0].save()
      this.assets.splice(assetIndex, 1, this.currencies[currencyIndex])
      this.assetModel[assetIndex] = ''

      this.onCompare()
    },
    async onFetchHistory(assetIndex, currencyIndex) {

      const payload = {
        days: '1D',
        sort: 'asc',
        token_address: this.currencies[currencyIndex].token_address,
        chain: this.currencies[currencyIndex].chain,
        cancelReq: true,
      }

      this.chartLoading = true
      const res = await this.loadHistory(payload)

      this.assets[assetIndex] = {
        ...this.assets[assetIndex],
        'data': res.data,
        'dataLabel': res.label,
      }

      this.chartLoading = false

      commit('compare/SET_DAPP_ASSETS', this.onParse(this.assets))
      this.$set(this, 'currencies', this.list)
    },
    onSearchCurrency(index) {
      this.currencyLoading[index] = true

      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        await this.searchCurrencies(index)
      }, 500)
    },
    async searchCurrencies(index) {
      let temp = []
      const query = this.assetModel[index]

      // Search array list first
      temp = this.list.filter((item) => {
        return item.token_address.match(query)
      })

      // if temp is empty, Search database
      if (temp.length < 1) {
        const payload = {
          token_address: query,
          rows: 100000,
          page: 1,
          sort: 'asc',
          returnResult: true,
        }

        temp = await this.loadCurrencyList(payload)
      }

      this.$set(this, 'currencies', temp)
      this.error_search = this.currencies.length < 1 ? `${this.$t('NoResultsMatch')} '${query}'` : ''
      // this.currencyLoading[index] = false
    },
    async onClickAsset(index) {
      await new Promise((r) => setTimeout(r, 300))

      this.$nextTick(() => {
        const asset = `asset${index}`

        this.assets[index].expanded = this.assets[index].expanded ? true : false
        this.$refs[asset][0].focus()
      })
    },
    onAddAsset() {
      if (this.assets.length < 8) {
        const temp = this.list.filter((o) => o.calc_rank === 1)

        this.assets.push(temp[0])
      }
    },
    async onRemoveAsset(idx) {
      if (this.assets.length > 2) {
        this.chartLoading = true
        this.assets.splice(idx, 1)

        await this.onSetChartData()
      }
    },
    async onFetchCurrencies() {
      const payload = {
        rows: 100,
        page: 1,
        sort: 'asc',
      }

      this.loading = true
      await this.loadCurrencyList(payload)
      this.$set(this, 'currencies', this.list)
      const temp = this.list.filter((o) => o.calc_rank === 1 || o.calc_rank === 2)

      this.$set(this, 'assets', this.onParse(temp))
      commit('compare/SET_DAPP_ASSETS', this.onParse(temp))
      this.loading = false

      await this.onCompare()
      // await this.onFetchAllChainActivity()
    },
    onParse(param) {
      return JSON.parse(JSON.stringify(param))
    },
    convertValue(val) {
      return val.toLocaleString('fullwide', { useGrouping: false })
    },
    checkLongValue(val) {
      const valueLen = this.convertValue(val)

      if (valueLen.length > 30)
        return helper.abbreviateNumber(valueLen)
      else
        return this.helper.convertToBigNumber(val, 0)
    },
    loadCurrencyList: call(`compare/${DAPP_LIST_REQUEST}`),
    loadHistory: call(`compare/${DAPP_HISTORY_REQUEST}`),
  },
}
</script>
