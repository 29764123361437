<template>
  <v-row dense class="mb-4">
    <v-col cols="12">
      <v-row dense>
        <v-col>
          <h1>{{ $t('Compare') }}</h1>
        </v-col>
      </v-row>
      <v-divider class="my-2"/>
      <v-col v-if="loading" >
        <Loader class="pt-16 pb-10"/>
      </v-col>
      <v-col v-else class="pa-0">
        <v-row dense>
          <v-col
            v-for="(asset, index) in assets"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card class="pa-3 my-xs-5 elevation-0 card_bg3">
              <v-row dense>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="11" class="asset-menu">
                      <v-menu
                        :ref="`assetMenu${index}`"
                        offset-y
                        z-index="4"
                        nudge-left="1"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ attrs, on }">
                          <button
                            type="button"
                            class="asset-btn secondary_3 elevation-0 text-capitalize body-2"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="onClickAsset(index)"
                          >
                            <span class="ellipsis">{{ asset.name }}</span>
                            <v-icon class="float-right">mdi-chevron-down</v-icon>
                          </button>
                        </template>

                        <v-card class="dropdown-card">
                          <v-card-title class="dropdown-card-title px-1 py-2">
                            <v-text-field
                              :ref="`asset${index}`"
                              v-model="assetModel[index]"
                              class="pa-0 ma-0"
                              hide-details
                              outlined
                              dense
                              flat
                              loader-height="3"
                              :loading="currencyLoading[index]"
                              @keydown="onSearchCurrency(index)"
                            />
                          </v-card-title>

                          <v-list class="dropdown-card-list">
                            <div v-if="currencies.length > 0">
                              <v-list-item
                                v-for="(curr, idx) in currencies"
                                :key="curr.name"
                                class="px-1"
                                @click="onSelectCurrency(index, idx)"
                              >
                                <span class="ml-2">{{ curr.name }}</span>
                                <small class="ml-1 mt-1 grey--text">{{ curr.symbol }}</small>
                              </v-list-item>
                            </div>
                            <span v-else class="ml-2">{{ error_search }}</span>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col cols="1" class="flex-grow-0 text-right">
                      <v-btn icon class="button-tiny grey--text mt-1" @click="onRemoveAsset(index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                  <p class="mt-2">
                    <span class="float-left grey--text">{{ $t('Rank') }}</span>
                    <span class="float-right secondary--text">{{ asset ? asset.cmc_rank : null }}</span>
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                  <p class="mt-2">
                    <span class="float-left grey--text">{{ $t('Price') }}</span>
                    <span class="float-right secondary--text">
                      <v-icon small :class="`body-2 mt-m-1px icon-${selected_currency.toLowerCase()}`"/>
                      {{ asset ? `${ helper.roundDecimalPlaces(asset.price) }` : null }}
                    </span>
                  </p>
                </v-col>
                <v-col cols="12">
                  <p>
                    <span class="float-left grey--text">{{ `${$t('Volume')} ${$t('24H')}` }}</span>
                    <span class="float-right secondary--text">
                      {{ asset ? `${ asset.volume_24h }`: null }}
                    </span>
                  </p>
                </v-col>
                <v-col cols="12">
                  <p>
                    <span class="float-left grey--text">{{ $t('MarketCap') }}</span>
                    <span class="float-right secondary--text">
                      {{ asset ? helper.convertToBigNumber(asset.market_cap) : null }}
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            v-if="assets.length < 4"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="d-flex align-center asset-btn-wrap"
          >
            <v-btn
              large
              class="secondary_3 mx-auto text-capitalize body-2 px-4 white--text"
              @click="onAddAsset"
            >
              {{ $t('AddAsset') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="my-5"/>
        <v-card class="elevation-0 pa-3 pa-md-5 card_bg">
          <v-row dense class="mb-5">
            <v-col cols="12" sm="3" md="2">
              <v-btn-toggle
                class="toggle-controls v-btn-group v-btn-group--3"
                dense
                mandatory
              >
                <v-btn class="uncapitalize body-2" @click="onChangeChartType(1)">
                  {{ $t('MultiAxis') }}
                </v-btn>
                <v-btn class="uncapitalize body-2" @click="onChangeChartType(2)">
                  {{ $t('MultiChart') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <v-btn-toggle
                class="justify-center d-xs-flex toggle-controls v-btn-group v-btn-group--4 d-none d-lg-flex"
                dense
                mandatory
              >
                <v-btn
                  v-for="(btn, idx) in btnGroupDetails"
                  :key="idx"
                  class="body-2 uncapitalize"
                  :input-value="btn.selected"
                  @click="onChangeView(idx)"
                >
                  {{ btn.name }}
                </v-btn>
              </v-btn-toggle>

              <Menu
                :button-size="`primary no-hover-bg uncapitalize d-flex d-lg-none`"
                :selected-text="selected_tab.name"
                :list-items="btnGroupDetails"
                key-item="name"
                @click="onChangeViewMenu($event)"
              />
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <v-btn-toggle
                class="float-right v-btn-group v-btn-group--4 toggle-controls d-none d-lg-flex"
                dense
                mandatory
              >
                <v-btn
                  v-for="btn in common.daysGroup"
                  :key="btn.name"
                  :input-value="btn.selected"
                  class="body-2 uncapitalize px-2"
                  @click="onChangeDays(btn)"
                >
                  <span>{{ btn.name }}</span>
                </v-btn>
              </v-btn-toggle>

              <Menu
                :button-size="`primary no-hover-bg uncapitalize d-flex d-lg-none`"
                :selected-text="selected_days.name"
                :list-items="common.daysGroup"
                key-item="name"
                @click="onChangeDaysMenu($event)"
              />
            </v-col>
          </v-row>
          <div id="tooltip"></div>
          <v-row dense>
            <v-col cols="12">
              <Loader v-if="chartLoading" :height="`500px`" />
              <template v-else>
                <template v-if="chartType === 0 || chartType === 1">
                  <LineChart :chart-data="simpleDataset" :is-multiaxis="true" />
                </template>
                <template v-else>
                  <v-row>
                    <v-col
                      v-for="asset in simpleDataset"
                      :key="`${asset.symbol}-${Math.random()}`"
                      cols="12"
                      sm="6"
                    >
                      <LineChart :chart-data="asset" />
                    </v-col>
                  </v-row>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import {
  CURRENCY_LIST_REQUEST,
  CURRENCY_REQUEST,
  CURRENCY_HISTORY_REQUEST,
  CHAIN_HISTORY_REQUEST,
} from '@/store/compare/action'
import { get, call, commit, dispatch } from 'vuex-pathify'
import Loader from '@/components/molecules/Loader'
import Menu from '@/components/atoms/Menu'
import helper from '@/utils/helper'
import LineChart from '@/components/organisms/chart/Compare'
import constants from '@/constants/constants'
import common from '@/shared/common'

export default {
  name:'CompareCurrencies',
  components: {
    Loader,
    LineChart,
    Menu,
  },
  data: () => ({
    common: common,
    helper: helper,
    loading: true,
    minAsset: 2,
    maxAsset: 8,
    timeout: null,
    error_search: '',
    assets: [],
    chains: [],
    assetMenu: [],
    assetModel: [],
    currencies: [],
    currencyLoading: [],
    chartLoading: true,
    chainLoading: true,
    viewType: 1,
    chainHistoryDataset: [],
    days: '1D',
    chartType: 1,
    simpleDataset: [],
    singleChainDataset: [],
    selected_tab: { name: 'Price', icon: 'mdi-currency-usd', selected: true },
    selected_days: { name: '1D', selected: true },
    colors: [
      constants.chartAxisColors.primary,
      constants.chartAxisColors.secondary_3,
      constants.chartAxisColors.secondary_1,
      constants.chartAxisColors.red,
      constants.chartAxisColors.darkyellow,
      constants.chartAxisColors.lightgrey,
      constants.chartAxisColors.cyan,
    ],
    btnGroupDetails: [
      { name: 'Price', icon: 'mdi-currency-usd', selected: true },
      { name: 'Volume', icon: 'mdi-chart-box-outline', selected: false },
      { name: 'Market Cap', icon: 'mdi-chart-gantt', selected: false },
      { name: 'Activity', icon: 'mdi-chart-line', selected: false },
    ],
    rules: {
      required: [(v) => !!v || 'Required.'],
    },
  }),
  computed: {
    list: get('compare/currencies'),
    selected_currency: get('getGlobalFiat'),
    current_fiat: get('getGlobalFiat'),
  },
  watch: {
    '$store.state.fiat': {
      async handler(value) {
        await this.onFetchCurrencies()
        this.onChangeChartType(1)
      },
      deep: true,
    },
  },
  async beforeMount() {
    this.viewType = 0
    if (this.list.length > 0) {
      this.loading = false
      this.chartLoading = false
      this.chainLoading = false
      const temp = this.$store.get('compare/assets')
      const tempChains = this.$store.get('compare/chains')

      this.$set(this, 'currencies', this.list)
      this.$set(this, 'assets', this.onParse(temp))
      this.$set(this, 'chains', this.onParse(tempChains))

      this.onChangeView(this.viewType)
      this.onSetChainHistory()
      this.onChangeChartType(1)
    } else {
      await this.onFetchCurrencies()
      this.onChangeChartType(1)
    }

    commit('compare/SET_VIEW', this.viewType)
  },
  methods: {
    async onChangeFiat() {
      this.assets.forEach((o, idx) => {
        const payload = {
          description: this.search,
          rows: 100,
          page: 1,
          sort: 'asc',
          currency: this.selected_currency,
        }

      })
    },
    onChangeChartType(type) {
      this.chartType = type
      this.simpleDataset = []

      if (this.viewType < 3) {
        switch (this.chartType) {
        case 0: {
          this.assets.forEach((o, idx) => {
            this.simpleDataset.push({
              name: o.symbol,
              symbol: o.symbol,
              labels: o.dataLabel,
              data: this.viewType === 0 ? o.dataPrice : this.viewType === 1 ? o.data : o.dataCap,
              id: 'bcm',
              simple: true,
            })
          })

          break
        }
        case 1: {
          this.assets.forEach((o, idx) => {
            this.simpleDataset.push({
              name: o.symbol,
              symbol: o.symbol,
              labels: o.dataLabel,
              data: this.viewType === 0 ? o.dataPrice : this.viewType === 1 ? o.data : o.dataCap,
              id: o.id,
              color: this.colors[idx],
              simple: false,
            })
          })
          break
        }
        case 2: {
          this.assets.forEach((o, idx) => {
            this.simpleDataset.push([
              {
                name: o.symbol,
                symbol: o.symbol,
                labels: o.dataLabel,
                color: this.colors[idx],
                data: this.viewType === 0 ? o.dataPrice : this.viewType === 1 ? o.data : o.dataCap,
                id: 'bcm',
                simple: true,
              },
            ])
          })
          break
        }
        }
      } else {
        if (this.chartType === 1) {
          this.chains.forEach((o, idx) => {
            this.simpleDataset.push({
              ...o,
              data: o.data,
              labels: o.labels,
              color: this.colors[idx],
              id: this.chartType === 0 ? 'bcm' : o.id,
              simple: this.chartType === 0 ? true : false,
            })
          })
        } else {
          this.chains.forEach((o, idx) => {
            this.simpleDataset.push([{
              ...o,
              data: o.data,
              labels: o.labels,
              color: this.colors[idx],
              id: this.chartType === 0 ? 'bcm' : o.id,
              simple: this.chartType === 0 ? true : false,
            }])
          })
        }
      }
    },
    onChangeView(val) {
      this.btnGroupDetails.forEach((o, index) => {
        o.selected = index === val ? true : false
      })

      this.selected_tab = this.btnGroupDetails[val]

      this.viewType = val
      this.simpleDataset = []
      commit('compare/SET_VIEW', val)

      this.onChangeChartType(this.chartType)
    },
    onChangeViewMenu(item) {
      this.btnGroupDetails.forEach((o, index) => {
        if (o.name.toLowerCase() === item.name.toLowerCase()) {
          o.selected = true
          this.selected_tab = o
          this.viewType = index
          this.simpleDataset = []
          commit('compare/SET_VIEW', index)
        } else {
          o.selected = false
        }
      })

      this.onChangeChartType(this.chartType)
    },
    async onChangeDays(val) {
      this.days = val.name

      this.common.daysGroup.forEach((o) => {
        o.selected = o.name === val.name ? true : false
      })

      this.selected_days = val
      await this.onCompare()
      await this.onFetchAllChainActivity()
    },
    async onChangeDaysMenu(item) {
      this.common.daysGroup.forEach((o) => {
        this.days =  o.name.toLowerCase() === item.name.toLowerCase() ? o.name : this.days
        o.selected = o.name === item.name ? true : false
      })

      this.selected_days = item
      await this.onCompare()
      await this.onFetchAllChainActivity()
    },
    async onFetchChainActivity(currency, assetIndex) {

      const payload = {
        days: this.days,
        sort: 'asc',
        id: currency.id,
        currency: this.selected_currency,
      }

      this.chainLoading = true
      const res = await this.loadChainActivityHistory(payload)

      this.chains[assetIndex] = {
        ...this.chains[assetIndex],
        'symbol': currency.symbol,
        'data': res.data,
        'label': res.label,
      }

      commit('compare/SET_CHAIN_HISTORY', this.onParse(this.chains))

      this.chainLoading = false
    },
    async onFetchAllChainActivity() {
      this.chainLoading = true
      for await (const [i, item] of this.assets.entries()) {
        const payload = {
          days: this.days,
          sort: 'asc',
          id: this.assets[i].id,
          currency: this.selected_currency,
        }
        const res = await this.loadChainActivityHistory(payload)

        this.$set(this.chains, i, {
          ...this.chains[i],
          'id': item.id,
          'symbol': item.symbol,
          'data': res.data,
          'labels': res.label,
        })
      }

      this.chainHistoryDataset = this.chains

      commit('compare/SET_CHAIN_HISTORY', this.onParse(this.chains))

      this.chainLoading = false
    },
    onSetChainHistory() {
      const tempData = []

      this.chains.forEach((o, idx) => {
        tempData.push({
          ...o,
          data: o.data,
          labels: o.labels,
        })
      })

      this.chainHistoryDataset = tempData
    },
    async onCompare() {
      // fetch currencies
      this.assets.forEach((o, idx) => {
        o['label'] = []
        o['data'] = []
      })

      for await (const [i, item] of this.assets.entries()) {
        const payload = {
          sort: 'asc',
          id: item.id,
          currency: this.selected_currency,
          days: this.days,
        }

        this.chartLoading = true
        const res = await this.loadCurrencyHistory(payload)

        this.chartLoading = false

        this.$set(this.assets, i, {
          ...this.assets[i],
          'data': res.data, // Volume
          'dataLabel': res.label,
          'dataPrice': res.price, // Price
          'dataCap': res.cap, // Market Cap
        })
      }

      commit('compare/SET_ASSETS', this.onParse(this.assets))

      this.onChangeView(this.viewType)
    },
    async onSelectCurrency(assetIndex, currencyIndex) {
      this.$refs[`assetMenu${assetIndex}`][0].save()
      this.assets.splice(assetIndex, 1, this.currencies[currencyIndex])
      this.assetModel[assetIndex] = ''

      const currency = this.currencies[currencyIndex]

      await this.onFetchHistory(currency, assetIndex)
      await this.onFetchChainActivity(currency, assetIndex)
      this.onChangeChartType(this.chartType)
    },
    async onFetchHistory(currency, assetIndex) {

      const payload = {
        days: this.days,
        sort: 'asc',
        id: currency.id,
        currency: this.selected_currency,
      }

      this.chartLoading = true
      const res = await this.loadCurrencyHistory(payload)

      this.assets[assetIndex] = {
        ...this.assets[assetIndex],
        'data': res.data,
        'dataLabel': res.label,
        'dataPrice': res.price,
        'dataCap': res.cap,
      }

      this.chartLoading = false

      commit('compare/SET_ASSETS', this.onParse(this.assets))
      this.$set(this, 'currencies', this.list)
    },
    onSearchCurrency(index) {
      this.currencyLoading[index] = true

      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        await this.searchCurrencies(index)
      }, 400)

    },
    async searchCurrencies(index) {
      let temp = []
      const query = this.assetModel[index]

      // if temp is empty, Search database
      if (query.length > 1) {
        const payload = {
          description: query,
          rows: 100000,
          page: 1,
          sort: 'asc',
          currency: this.selected_currency,
        }

        temp = await this.loadCurrencySearch(payload)
      } else {
        temp = this.list
      }

      this.$set(this, 'currencies', temp)
      this.currencyLoading[index] = false
      this.error_search = this.currencies.length < 1 ? `${this.$t('NoResultsMatch')} '${query}'` : ''
    },
    async onClickAsset(index) {
      await new Promise((r) => setTimeout(r, 300))

      this.$nextTick(() => {
        const asset = `asset${index}`

        this.assets[index].expanded = this.assets[index].expanded ? true : false
        this.$refs[asset][0].focus()
      })
    },
    onAddAsset() {
      if (this.assets.length < 4) {
        const temp = this.list.filter((o) => o.cmc_rank === 1)

        this.assets.push(temp[0])
      }
    },
    async onRemoveAsset(idx) {
      if (this.assets.length > 2) {
        this.chartLoading = true
        this.chainLoading = true
        this.assets.splice(idx, 1)
        this.chains.splice(idx, 1)

        await this.onCompare()
        await this.onFetchAllChainActivity()
      }
    },
    async onFetchCurrencies() {
      let temp = []
      const payload = {
        description: this.search,
        rows: 100,
        page: 1,
        sort: 'asc',
        currency: this.selected_currency,
      }

      this.loading = true
      await this.loadCurrencyList(payload)
      this.$set(this, 'currencies', this.list)

      if (this.assets.length < 3)
        temp = this.list.filter((o) => o.cmc_rank === 1 || o.cmc_rank === 2)
      else {
        temp = this.list.filter((el) => {
          return this.assets.some((a) => {
            return a.id === el.id
          })
        })
      }

      this.$set(this, 'assets', this.onParse(temp))
      commit('compare/SET_ASSETS', this.onParse(temp))
      this.loading = false

      await this.onCompare()
      await this.onFetchAllChainActivity()
    },
    onParse(param) {
      return JSON.parse(JSON.stringify(param))
    },
    loadCurrencyList: call(`compare/${CURRENCY_LIST_REQUEST}`),
    loadCurrencySearch: call(`compare/${CURRENCY_REQUEST}`),
    loadCurrencyHistory: call(`compare/${CURRENCY_HISTORY_REQUEST}`),
    loadChainActivityHistory: call(`compare/${CHAIN_HISTORY_REQUEST}`),
  },
}
</script>
<style lang="scss" scoped>
.theme--dark {
  &.button-tiny {
    i {
      color: rgba(white, 0.3) !important;
    }
    &:hover {
      i {
        background-color: rgba(white, 0.08) !important;
      }
    }
  }
  &.v-card {
    &.v-sheet {
      &.dropdown-card {
        // background-color: lighten(black, 20%)!important;
      }
    }
  }
  .v-list {
    background-color: transparent !important;
    .v-list-item {
      &:hover {
        background-color: rgba(white, 0.08) !important;
      }
    }
  }
}

.theme--light {
  &.button-tiny {
    &:hover {
      i {
        background-color: rgba(black, 0.03) !important;
      }
    }
  }
  &.v-card {
    &.v-sheet {
      &.dropdown-card {
        // background-color: lighten(white, 30%)!important;
      }
    }
  }
  .v-list {
    background-color: transparent !important;
    .v-list-item {
      &:hover {
        background-color: rgba(black, 0.04) !important;
      }
    }
  }
  .asset-menu {
    > button {
      color: white;
      i {
        color: white;
      }
    }
  }
}

.asset-btn-wrap {
  min-height: 210px;
  @media (max-width: 500px) {
    min-height: 100px;
    button {
      width: 100% !important;
    }
  }
}

.button-tiny {
  height: 16px !important;
  width: 16px !important;
  i {
    font-size: 16px !important;
  }
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 30px;
}

.asset-menu {
  button {
    &:focus {
      outline: 0;
    }
    border-radius: 4px;
    text-align: left;
    width: 100%;
    span {
      float: left;
      padding: 5px;
      width: 88%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    i {
      margin-top: 3px;
      float: right;
    }
  }
}

.dropdown-card {
  min-width: 240px;
  max-height: 278px;
  overflow: hidden;
  &-list {
    cursor: pointer;
    padding: 0 5px 10px 0px;
    max-height: 230px;
    overflow-y: auto;
  }

  &-title {
    .v-input {
      &.v-text-field--outlined {
        &:not(.v-input--is-focused):not(.v-input--has-state)
          > .v-input__control
          > .v-input__slot
          fieldset {
          color: $white !important;
        }
        &.v-input--is-focused fieldset {
          color: $white;
          border: 1px solid $white !important;
        }
      }
      input {
        color: white;
        &::placeholder {
          color: $white;
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
