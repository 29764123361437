<template>
  <v-container fluid>
    <CompareCurrencies />
    <!-- <CompareDApp /> -->
  </v-container>
</template>

<script>
import CompareCurrencies from '@/components/organisms/compare/Currencies'
import CompareDApp from '@/components/organisms/compare/DApp'

export default {
  name:'Compare',
  components: {
    CompareCurrencies,
    // CompareDApp,
  },
}
</script>

<style lang="scss" scoped>
.container {
  @media (max-width: 600px) {
    padding: 0;
  }
}
</style>
